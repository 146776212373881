import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { Observable, map } from "rxjs";
import { ReportService } from "./report.service";

@Injectable({
    providedIn: 'root',
})
export class PartnerService {
    constructor(
        private http: HttpClient,
        private reportService: ReportService
    ) { }

    // Get bulk update sample (.csv)
    getBulkUpdateSample(): Observable<any> {
        const csvHeader = {
            headers: new HttpHeaders({
                'Accept': 'text/csv'
            }),
            responseType: 'blob' as 'json'
        };

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.partnerPath}/bulk-update-sample`, csvHeader).pipe(
            map(res => {
                this.reportService.downloadCSVFile(res, `Bulk Update Sample`, 'Sample generated');
            })
        );
    }

    // Get partner listing
    getPartnerListing(market: string, rank: string, status: string, page: number, size: number, search: string = ''): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            "page": page,
            "size": size,
            "status": status !== 'ALL' ? status : null,
            "rank": rank !== 'ALL' ? rank : null,
            "market": market !== 'ALL' ? market : null,
            "search": search !== '' ? search : null
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.partnerPath}`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get partner details
    getPartnerDetails(id: string): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.partnerPath}/${id}`).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Update partner
    updatePartnerDetails(id: string, firstName: string, lastName: string, nickName: string, rank: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            first_name: firstName,
            last_name: lastName,
            nick_name: nickName,
            tier: rank
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.partnerPath}/${id}`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Bulk update partner (Upload .csv) 
    bulkUpdatePartnerDetails(file: File): Observable<any> {
        let formData = new FormData();
        formData.append('file', file);

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.partnerPath}/bulk-update`, formData).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Remove partner
    deletePartner(id: string): Observable<any> {
        return this.http.delete<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.partnerPath}/${id}`).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Request reset partner password
    resetPartnerPassword(id: string): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.partnerPath}/${id}/reset-password`, {}).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Add workshop participants
    addWorkshopParticipant(email: string, identifier: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            email: email
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.workshopPath}/add-participant/${identifier}`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get workshop registration listing
    getWorkshopRegistration(identifier: string): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.workshopPath}/registration/${identifier}`).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Resend workshop registration email
    resendWorkshopRegistrationEmail(id: string, email: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            email: email
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.workshopPath}/resend-registration-email/${id}`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    toastrErrorHeader!: string;
    toastrErrorDesc!: string;

    constructor(
        private http: HttpClient,
    ) { }

    // Validate Email Format
    validateEmail(email: string) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Not email
        if (!emailRegex.test(email)) {
            return false;
        }
        return true;
    }

    // Validate pasword criteria
    validatePassword(password: string): any {
        let errorList: string[] = [];
        const charLengthPattern = /^.{12,25}$/;
        const lowerCasePattern = /(?=.*[a-z])/;
        const upperCasePattern = /(?=.*[A-Z])/;
        const numberPattern = /(?=.*[0-9])/;
        const specialCharPattern = /(?=.*[^A-Za-z0-9])/;

        // Password length from 12 to 25 characters long
        if (!charLengthPattern.test(password)) {
            errorList.push('invalidCharLength');
        }

        //At least one lower case letter (a-z)
        if (!lowerCasePattern.test(password)) {
            errorList.push('invalidLowercase');
        }

        //At least one upper case letter (A-Z)
        if (!upperCasePattern.test(password)) {
            errorList.push('invalidUppercase');
        }

        //At least one number (0-9)
        if (!numberPattern.test(password)) {
            errorList.push('invalidNumber');
        }

        //At least one special character
        if (!specialCharPattern.test(password)) {
            errorList.push('invalidSpecialChar');
        }

        return errorList;
    }
}
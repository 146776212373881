import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { TextInputComponent } from '../../forms';
import { InputTextModel } from 'src/app/_models/form-field.model';
import { InputType } from 'src/app/enum';

@Component({
  selector: 'app-delete-content-confirmation-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule, TextInputComponent],
  templateUrl: './delete-content-confirmation-modal.component.html',
  styleUrl: './delete-content-confirmation-modal.component.scss'
})
export class DeleteContentConfirmationModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;
  @Input() isDefault: boolean = false;
  @Output() onConfirm = new EventEmitter<void>();

  deleteString: string = '';
  showError: boolean = false;
  inputEmailConfig: InputTextModel = {
    id: 'delete-confirmation-input',
    name: 'delete-confirmation-input',
    label: '',
    placeholder: 'delete',
    max_length: 100,
    value: this.deleteString,
    disabled: false,
    type: InputType.Text,
    required: false
  };

  dismissModal() {
    this.modal.dismiss();
  }

  confirmDelete() {
    // Other languages
    if (!this.isDefault) {
      this.modal.dismiss();
      this.modal.hidden.subscribe(() => {
        this.onConfirm.emit();
      });
      return;
    }

    // Default language (English)
    if (this.deleteString === 'delete') {
      this.modal.dismiss();
      this.modal.hidden.subscribe(() => {
        this.onConfirm.emit();
      });
    } else {
      this.showError = true;
    }
  }

  setDeleteValue(val: any) {
    this.showError = false;
    this.deleteString = val.value;
  }
}

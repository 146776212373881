<div class="list-table">
  <section *ngIf="tableSearch" class="table-list-search mb-4">
    <c-input-group>
      <span cInputGroupText><svg cIcon class="me-1" name="cilMagnifyingGlass"></svg></span>
      <input cFormControl id="table-list-search-input"
        placeholder="{{ searchTerms ? 'Search by ' + searchTerms : 'Search' }}" type="search"
        [formControl]="searchInput" />
    </c-input-group>
  </section>
  <div *ngIf="tableBodyData.length === 0">No record found.</div>
  <section *ngIf="tableBodyData.length > 0" class="table-list">
    <div *ngIf="totalAsset" class="table-top d-flex justify-content-center justify-content-lg-start mb-2">
      <div class="data-count fs-small gray-700"><span>{{totalAsset}}</span> records</div>
    </div>
    <table [striped]="true" cTable [responsive]="true" class="border-light">
      <thead cTableColor="dark">
        <tr>
          <th *ngFor="let col of tableHeadData" [ngClass]="col.class" scope="col">{{ col.value }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!tableBodyData">
          <div class="no-results d-flex flex-column align-items-center justify-content-center p-5">
            <div class="icon-img">
              <svg cIcon name="cilExclamationCircle"></svg>
            </div>
            No results
          </div>

        </ng-container>
        <ng-container *ngFor="let row of tableBodyData; index as i">
          <tr>
            <ng-container *ngFor="let col of row">
              <ng-container *ngIf="col.type === 'TEXT'">
                <td [ngClass]="col.class">
                  <ng-container *ngIf="col.url">
                    <a [title]="col.name || col.value" [routerLink]="col.url"
                      [queryParams]="col.fromPendingApproval ? { f: 'cGVuZGluZ0FwcHJvdmFs' }:null"
                      [target]="col.link_in_new_tab ? '_blank' : '_self'">{{ col.name ?? col.value }}</a>
                  </ng-container>
                  <span *ngIf="col.isDraft" class="label label-draft ms-1">DRAFT
                  </span>
                  <ng-container *ngIf="!col.url">
                    <span [class.text-danger]="col.isAlert">{{ col.name ?? col.value }}</span>
                  </ng-container>

                  <div *ngIf="col.subvalue" class="sub-value"><small><i>{{ col.subvalue }}</i></small></div>
                </td>
              </ng-container>

              <ng-container *ngIf="col.type === 'TEXT_WITH_THUMBNAIL'">
                <td [ngClass]="col.class" class="with-thumbnail">
                  <a *ngIf="col.url" [routerLink]="col.url"
                    class="d-flex flex-wrap align-items-center truncate-2-lines">
                    <figure *ngIf="col.asset_type === 'IMAGE'" class="mb-0 image-preview">
                      <img [src]="col.img_url" />
                    </figure>

                    <figure *ngIf="col.asset_type === 'DOCUMENT'" class="mb-0 document-preview">
                      <svg *ngIf="col.file_type === 'PDF'" cIcon name="cilFilePdf"></svg>
                      <svg *ngIf="col.file_type === 'DOC'" cIcon name="cilFileDoc"></svg>
                      <svg *ngIf="col.file_type === 'TXT'" cIcon name="cilDocument"></svg>
                      <svg *ngIf="col.file_type === 'ZIP'" cIcon name="cilFileArchive"></svg>
                      <svg *ngIf="col.file_type === 'XLS'" cIcon name="cilFileXls"></svg>
                      <svg *ngIf="col.file_type === 'CSV'" cIcon name="cilSpreadsheet"></svg>
                    </figure>
                    <div class="flex-1 ps-2">
                      <div [title]="col.name || col.value" class="truncate-2-lines">
                        {{ truncateName(col.name || col.value,15) }}
                      </div>
                      <div *ngIf="col.subvalue" class="sub-value"><small><i>{{ col.subvalue }}</i></small></div>
                    </div>
                  </a>

                  <div *ngIf="!col.url" class="d-flex flex-wrap align-items-center truncate-2-lines">
                    <figure *ngIf="col.asset_type === 'IMAGE'" class="mb-0 image-preview">
                      <img [src]="col.img_url" />
                    </figure>

                    <figure *ngIf="col.asset_type === 'DOCUMENT'" class="mb-0 document-preview">
                      <svg *ngIf="col.file_type === 'PDF'" cIcon name="cilFilePdf"></svg>
                      <svg *ngIf="col.file_type === 'DOC'" cIcon name="cilFileDoc"></svg>
                      <svg *ngIf="col.file_type === 'TXT'" cIcon name="cilDocument"></svg>
                      <svg *ngIf="col.file_type === 'ZIP'" cIcon name="cilFileArchive"></svg>
                      <svg *ngIf="col.file_type === 'XLS'" cIcon name="cilFileXls"></svg>
                      <svg *ngIf="col.file_type === 'CSV'" cIcon name="cilSpreadsheet"></svg>
                    </figure>
                    <div class="flex-1 ps-2">
                      <div [title]="col.name || col.value" class="truncate-2-lines">
                        {{ truncateName(col.name || col.value,15) }}
                      </div>

                      <div *ngIf="col.subvalue" class="sub-value"><small><i>{{ col.subvalue }}</i></small></div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container *ngIf="col.type === 'TAGS'">
                <td [ngClass]="col.class">
                  <div *ngIf="col.tags && col.tags.length > 0" class="d-flex flex-wrap">
                    <div *ngFor="let tag of col.tags.split(',')" class="label label-tag">
                      {{tag}}
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container *ngIf="col.type === 'DATE_RANGE'">
                <td [ngClass]="col.class">
                  {{ col.value }}
                  <div class="timezone">
                    <small><i>{{ col.timezone }}</i></small>
                  </div>

                </td>
              </ng-container>

              <ng-container *ngIf="col.type === 'ACTION_BUTTONS'">
                <td [ngClass]="col.class" class="pt-1">
                  <div class="d-flex flex-column align-items-start justify-content-start">
                    <ng-container *ngFor="let button of col.buttons">
                      <ng-container *ngIf="button.type === 'PREVIEW'">
                        <a cButton [href]="button.url" target="_blank" color="primary"
                          class="btn-xs m-1">Preview</a></ng-container>
                      <ng-container *ngIf="button.type === 'COPY_LINK'">
                        <button cButton color="primary" class="btn-xs m-1" (click)="copyLink()">Copy
                          link</button></ng-container>
                      <ng-container *ngIf="button.type === 'SELECT_FILE' && !button.selected">
                        <button cButton color="primary" class="btn-sm m-1"
                          (click)="onSelectClick(i)">Select</button></ng-container>
                      <ng-container *ngIf="button.type === 'SELECT_FILE' && button.selected">
                        <button cButton color="info" class="btn-sm m-1" (click)="onUnselectClick(i)"><i
                            class="cis-check me-1"></i> Selected</button></ng-container>
                      <ng-container *ngIf="button.type === 'RESEND_ADMIN_INVITATION' && button.show">
                        <button cButton color="primary" class="btn-xs m-1"
                          (click)="onResendAdminInvitationClick(i)">Resend invitation</button></ng-container>
                      <ng-container *ngIf="button.type === 'VIEW_REGISTRATION'">
                        <button cButton color="primary" class="btn-xs m-1"
                          (click)="onViewWorkshopRegistrationClick(i)">View registration</button></ng-container>
                      <ng-container *ngIf="button.type === 'RESEND_WORKSHOP_REGISTRATION'">
                        <button cButton color="primary" class="btn-xs m-1"
                          (click)="onResendWorkshopInvitationClick(i)">Resend registration email</button></ng-container>
                      <ng-container *ngIf="button.type === 'DOWNLOAD_BAP_COMPLETION_LIST'">
                        <button cButton color="primary" class="btn-xs m-1"
                          (click)="onDownloadBapTopicItemCompletionListClick()">Download completion
                          list</button></ng-container>
                    </ng-container>
                  </div>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </section>
</div>
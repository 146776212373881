<c-modal-header>
  <h5 cModalTitle>Discard Draft</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <p>Are you sure you want to discard this draft?</p>
  <p>This action cannot be undone.</p>
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" (click)="confirmDiscard()">Yes, discard this draft</button>
</c-modal-footer>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalModule, ButtonModule, FormModule, GridModule } from '@coreui/angular';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { ListTableComponent } from "../../../components/list-table/list-table.component";
import { SelectMenuInputComponent } from "../../forms/select-menu-input/select-menu-input.component";
import { ContentService } from 'src/app/_services/content.service';
import { TableBodyDataModel } from 'src/app/_models/select-content-modal.model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CMSContentName, CMSContentPath } from 'src/app/enum/content.enum';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { SpinnerIcon } from 'src/app/icons/icon-spinner';
import { ErrorService } from 'src/app/_services/error.service';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-select-content-modal',
  standalone: true,
  templateUrl: './select-content-modal.component.html',
  styleUrl: './select-content-modal.component.scss',
  imports: [ModalModule, ButtonModule, FormModule, FormsModule, ReactiveFormsModule, GridModule, CommonModule, IconModule, RouterLink, ListTableComponent, SelectMenuInputComponent, SpinnerIcon, ListPaginationComponent]
})
export class SelectContentModalComponent implements OnInit {
  @Input() modal: any;
  @Input() asset_type!: string;
  @Input() relationship!: { cardinality?: number, contentType: string, formControlName: string };
  @Input() selectedData!: any;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: { id: string, contentData: any } }>();
  @Output() onRemove = new EventEmitter<string>();
  @Output() onCreate = new EventEmitter<void>();

  adminAllowedLanguage !: number[];

  cmsData: {}[] = [];
  currentPage: number = 1;
  dynamicTableBodyData: TableBodyDataModel[][] = [];
  folderPath: boolean = true;
  language: number = 1;
  searchInput = new FormControl('');
  isLoading: boolean = false;
  // searchResults: boolean = false;
  showAsset: number = 15;
  totalAsset: number = 0;
  totalPage: number = 1;

  inputLanguageConfig = {
    id: 'language-input',
    name: 'language-input',
    label: 'Language',
    value: '1',
    disabled: false,
    error_message: '',
    options: [
      {
        value: '1',
        label: 'English',
        disabled: false,
        selected: false
      },
      {
        value: '4852764',
        label: 'Traditional Chinese',
        disabled: false,
        selected: false
      },
      {
        value: '5017431',
        label: 'Japanese',
        disabled: false,
        selected: false
      },
      {
        value: '5028697',
        label: 'Korean',
        disabled: false,
        selected: false
      },
      {
        value: '4561158',
        label: 'Indonesian',
        disabled: false,
        selected: false
      },
      {
        value: '5146990',
        label: 'Vietnamese',
        disabled: false,
        selected: false
      },
      {
        value: '4938438',
        label: 'Thai',
        disabled: false,
        selected: false
      }
    ]
  };

  tableHeadData = [
    {
      'value': 'Title / Name',
      'class': 'col-wide-sm sticky'
    },
    {
      'value': 'Tags',
      'class': 'col-wide-sm'
    },
    {
      'value': 'Actions',
      'class': 'col-wide-sm actions'
    }
  ];

  constructor(
    private contentService: ContentService,
    private errorService: ErrorService,
    private accountService: AccountService
  ) {
    // Check allowed language
    if (this.accountService.allowed_cms_languages.length === 0) {
      this.accountService.getAdminProfile();
    }
  }

  ngOnInit(): void {
    this.adminAllowedLanguage = this.accountService.allowed_cms_languages;

    // Not used for now; Currently using searchbar from <app-list-data>
    this.searchInput.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      this.currentPage = 1;
      this.getOrSearch();
    });
    this.getOrSearch();
  }

  dismissModal() {
    this.modal.dismiss();
  }

  getOrSearch() {
    this.isLoading = true;

    this.contentService.getContentListing(this.language, this.currentPage, this.showAsset, 0, 'ALL', this.relationship.contentType, this.searchInput.value ?? '').subscribe({
      next: res => {
        if (!res.data) {
          this.errorService.openGenericErrorModal(res.error, res.error_code);
          this.isLoading = false;
          return;
        }

        this.dynamicTableBodyData = [];

        if (res.data.contents.length === 0) {
          this.totalAsset = 0;
          this.totalPage = 1;
          this.currentPage = 1;
          this.isLoading = false;
          return;
        }

        const contentData = res.data.contents;
        const pageData = res.data.page;

        this.cmsData = res.data.contents;
        this.totalAsset = pageData.total_elements;
        this.totalPage = pageData.total_pages;

        contentData.forEach((data: any) => {
          const selectedObj = this.selectedData.find((obj: any) => obj.id === data.cms_data.identifier || obj.id === data.cms_data.inode);

          this.dynamicTableBodyData.push([
            { // Title
              'type': 'TEXT',
              'value': data.cms_data.title,
              'url': `/${CMSContentPath[this.relationship.contentType.toUpperCase() as keyof typeof CMSContentPath]}/${data.cms_data.inode}`,
              'link_in_new_tab': true,
              'class': 'content-id sticky',
              'identifier': data.cms_data.identifier
            },
            { // Tag
              'type': 'TAGS',
              'tags': data.cms_data.tags ?? ''
            },
            { // Actions
              'type': 'ACTION_BUTTONS',
              'buttons': [
                {
                  'type': 'SELECT_FILE',
                  'selected': selectedObj ? true : false,
                }
              ]
            }
          ])
        });

        this.isLoading = false;
      },
      error: err => {
        console.log(err);
      }
    })
  }

  setLanguage(data: any) {
    this.language = Number.parseInt(data.value);
    this.getOrSearch();
  }

  setSelection(val: any) {
    const selectedData = this.cmsData.find((data: any) => data.cms_data.identifier === val);
    this.onChange.emit({
      formControlName: this.relationship.formControlName,
      value: {
        id: val,
        contentData: selectedData
      }
    })
  }

  setUnselection(val: any) {
    this.onRemove.emit(val);
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.getOrSearch();
  }

  getContentName(contentType: string) {
    return CMSContentName[contentType.toUpperCase() as keyof typeof CMSContentName];
  }

  setSearchInput(data: string) {
    this.searchInput.patchValue(data);
    this.getOrSearch();
  }

  onCreateContent() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onCreate.emit();
    })
  }
}

<c-modal-header>
  <h5 cModalTitle>Delete Content</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <p>Are you sure you want to delete this content?</p>
  <ng-container *ngIf="isDefault; else otherLanguage">
    <p>Deleting <strong>default (English)</strong> content will also
      delete same content of the other languages. This action cannot be undone.</p>
    <p>Please type <strong class="text-danger">"delete"</strong> to confirm.</p>
    <app-text-input [config]="inputEmailConfig" (onChange)="setDeleteValue($event)"></app-text-input>
    <div *ngIf="showError" class="form-error">
      Please type the word "delete" to confirm.
    </div>
  </ng-container>

  <ng-template #otherLanguage>
    <p>This action cannot be undone.</p>
  </ng-template>
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" (click)="confirmDelete()" [disabled]="isDefault && deleteString === ''">Yes, delete
    this content</button>
</c-modal-footer>
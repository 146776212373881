import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { SelectMenuInputComponent } from "../select-menu-input/select-menu-input.component";
import { FormFieldModel, InputRelationshipModel, InputSelectModel } from 'src/app/_models/form-field.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectContentModalComponent } from '../../modal';
import { ContentService } from 'src/app/_services/content.service';
import { RouterLink } from '@angular/router';
import { CMSContentPath } from 'src/app/enum/content.enum';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-single-content-selector',
  standalone: true,
  templateUrl: './single-content-selector.component.html',
  styleUrl: './single-content-selector.component.scss',
  imports: [FormModule, CommonModule, ButtonModule, IconModule, SelectMenuInputComponent, RouterLink]
})
export class SingleContentSelectorComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string[] }>();

  contentPath: string = '';
  data!: InputRelationshipModel;
  selectContentModalRef!: any;
  adminAllowedLanguage !: number[];

  // Form input - Content type
  inputContentTypeConfig: InputSelectModel = {
    id: 'content-type-input',
    name: 'content-type-input',
    disabled: false,
    error_message: '',
    required: false,
    value: '',
    options: []
  };
  contentTypeError: boolean = false;

  constructor(
    private modalService: NgbModal,
    private contentService: ContentService,
    private accountService: AccountService
  ) {
    // Check allowed language
    if (this.accountService.allowed_cms_languages.length === 0) {
      this.accountService.getAdminProfile();
    }
  }

  ngOnInit(): void {
    this.adminAllowedLanguage = this.accountService.allowed_cms_languages;

    this.data = this.config as InputRelationshipModel;
    this.data.selected_content_type = this.getContentType(this.data.selected_content_type ?? '');
    this.contentPath = CMSContentPath[this.data.selected_content_type.toUpperCase() as keyof typeof CMSContentPath];

    if (this.data.type && this.data.type.length > 0) {
      this.inputContentTypeConfig.id = this.data.id;
      this.inputContentTypeConfig.name = this.data.name ?? this.data.id;
      this.inputContentTypeConfig.value = this.data.selected_content_type;

      this.data.type.forEach((type: any) => {
        this.inputContentTypeConfig.options!.push({
          value: this.getContentType(type),
          label: type,
          disabled: false,
          selected: false
        })
      })
    }

    let tempData = this.config as InputRelationshipModel;
    tempData.selected_data.forEach((data: any, index) => { // forEach so it does not throw error when no data.
      this.contentService.getContentByIdentifier(data.identifier, tempData.language ?? 1).subscribe({
        next: res => {
          if (res.data) {
            // Set selected data information
            this.data.selected_data[index].id = res.data.cms_data.contentId || res.data.cms_data.inode || res.data.cms_data.identifier;
            this.data.selected_data[index].name = res.data.cms_data.title;
            this.data.selected_data[index].tags = res.data.cms_data.tags;
            this.data.selected_data[index].url = res.data.cms_data.coverImage || res.data.cms_data.profileImage || '';
            this.data.selected_data[index].status = res.data.cms_data.approvalStatus;
            this.data.selected_data[index].hasPublished = res.data.api_data.has_published;
          } else {
            // Remove content if not found
            this.data.selected_data = [];
            this.inputContentTypeConfig.value = '';
          }
        }
      })
    });
  }

  contentLink(inode: string) {
    return `${this.contentPath}/${inode}`;
  }

  getContentType(type: string) {
    switch (type) {
      case 'Event':
        return 'SbuxEvent';
      case 'Event Video':
        return 'SbuxEventVideo';
      case 'Learning Topic':
        return 'SbuxLearningTopic';
      case 'Learning Lesson':
        return 'SbuxLearningLesson';
      case 'Workshop':
        return 'SbuxWorkshopArchive';
      case 'Competitions':
        return 'SbuxCompetition';
      default:
        return '';
    }
  }

  onContentTypeSelected(data: { formControlName: string; value: string; }) {
    this.data.selected_content_type = data.value;
    this.contentPath = CMSContentPath[this.data.selected_content_type.toUpperCase() as keyof typeof CMSContentPath];
    this.data.selected_data = [];
  }

  onRemoveButtonClick() {
    this.data.selected_data = [];
    this.onChange.emit({ formControlName: this.data.id, value: [] });
  }

  onSelectButtonClick() {
    if (!this.data.selected_content_type) {
      this.contentTypeError = true;
      return;
    }
    this.openSelectContentModal('SELECT_CONTENT');
  }

  openSelectContentModal(event_session: string) {
    this.selectContentModalRef = this.modalService.open(SelectContentModalComponent, {
      centered: true,
      windowClass: 'select-content-modal',
      scrollable: true,
      size: 'lg'
    });
    this.selectContentModalRef.componentInstance.modal = this.selectContentModalRef;
    this.selectContentModalRef.componentInstance.event_session = event_session;
    this.selectContentModalRef.componentInstance.relationship = { cardinality: 1, contentType: this.data.selected_content_type, formControlName: this.data.id };
    this.selectContentModalRef.componentInstance.selectedData = this.data.selected_data;
    this.selectContentModalRef.componentInstance.onChange.subscribe((value: any) => {
      const cmsData = value.value.contentData.cms_data;
      const apiData = value.value.contentData.api_data;

      this.data.selected_data = [];
      this.data.selected_data.push({
        id: cmsData.inode,
        identifier: cmsData.identifier,
        name: cmsData.title,
        tags: cmsData.tags,
        url: cmsData.coverImage || cmsData.profileImage || '',
        status: cmsData.status,
        hasPublished: apiData.has_published
      })
      this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data.map(item => item.id) });
      this.selectContentModalRef.dismiss();
    });
  }
}

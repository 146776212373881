<c-modal-header>
  <h5 cModalTitle>
    {{truncateName(fileNameValue , 50)}}
  </h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <c-row>
    <c-col lg="3">
      <figure *ngIf="asset_data.asset_type === 'IMAGE'" class="image-preview">
        <img [src]="asset_data.url">
      </figure>

      <figure *ngIf="asset_data.asset_type === 'DOCUMENT'" class="document-preview">
        <svg *ngIf="asset_data.file_type.toUpperCase() === 'PDF'" cIcon name="cilFilePdf"></svg>
        <svg *ngIf="asset_data.file_type.toUpperCase() === 'DOC'" cIcon name="cilFileDoc"></svg>
        <svg *ngIf="asset_data.file_type.toUpperCase() === 'TXT'" cIcon name="cilDocument"></svg>
        <svg *ngIf="asset_data.file_type.toUpperCase() === 'ZIP'" cIcon name="cilFileArchive"></svg>
        <svg *ngIf="asset_data.file_type.toUpperCase() === 'XLS'" cIcon name="cilFileXls"></svg>
        <svg *ngIf="asset_data.file_type.toUpperCase() === 'CSV'" cIcon name="cilSpreadsheet"></svg>
      </figure>
    </c-col>
    <c-col lg="9">
      <div class="information">
        <h5 class="mb-3">Information</h5>
        <c-row>
          <c-col sm="6" class="mb-3">
            <div class="data-label">
              <svg cIcon name="cilFile" class="me-1"></svg> File format
            </div>
            <div class="data">{{asset_data.file_type.toUpperCase()}}</div>
          </c-col>

          <c-col sm="6" class="mb-3" *ngIf="asset_data.asset_type === 'IMAGE'">
            <div class="data-label">
              <svg cIcon name="cilCrop" class="me-1"></svg> Image size
            </div>
            <div class="data">{{asset_data.image_width}}px x {{asset_data.image_height}}px</div>
          </c-col>

          <c-col sm="6" class="mb-3">
            <div class="data-label">
              <svg cIcon name="cilMemory" class="me-1"></svg> File size
            </div>
            <div class="data">{{fileSizeConversion(asset_data.size)}}</div>
          </c-col>

          <c-col sm="6" class="mb-3">
            <div class="data-label">
              <svg cIcon name="cilInboxIn" class="me-1"></svg> Date added
            </div>
            <div class="data">10:00 AM PST, 1 August 2024<br><i>by John Doe</i></div>
          </c-col>
        </c-row>
      </div>
    </c-col>

  </c-row>

  <div class="py-4 px-1 pb-0">
    <div class="tags mb-4 pb-3 pt-4 border-top border-light">
      <div class="form-group mb-4">
        <label cLabel for="file-name-input">File name. Max 50 characters</label>
        <input cFormControl id="file-name-input" maxlength="50" [(ngModel)]="asset_data.name">
      </div>
      <div class="form-group">
        <label cLabel for="tags-input">Tags</label>
        <input cFormControl id="tags-input" [(ngModel)]="asset_data.tags"
          placeholder="Add tagging for the content. Separate each tag with comma. E.g. Coffee, Cold Brew, Frappuccino">
      </div>
    </div>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" class="btn-copy-link" (click)="submit()" [disabled]="!asset_data.name">Save</button>
</c-modal-footer>
<c-modal-header>
  <h5 cModalTitle>Add Assets</h5>
  <button *ngIf="currentStep !== 'PROCESS'" cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <ng-container>
    <div *ngIf="currentStep === 'PROCESSING'" class="text-warning mb-4">Uploading file, do not close or refresh this
      window.</div>
    <c-card *ngFor="let asset of assetsToBeUploaded; let i = index" class="mb-3">
      <c-card-body>
        <div class="d-flex flex-wrap align-items-center">
          <!-- Image thumbnail -->
          <figure *ngIf="asset.file_type === 'IMAGE'" class="image-preview">
            <img [src]="asset.asset_url">
          </figure>

          <!-- Document thumbnail -->
          <figure *ngIf="asset.file_type !== 'IMAGE'" class="document-preview">
            <svg *ngIf="asset.file_type === 'PDF'" cIcon name="cilFilePdf"></svg>
            <svg *ngIf="asset.file_type === 'DOC' || asset.file_type === 'DOCX'" cIcon name="cilFileDoc"></svg>
            <svg *ngIf="asset.file_type === 'TXT'" cIcon name="cilDocument"></svg>
            <svg *ngIf="asset.file_type === 'ZIP'" cIcon name="cilFileArchive"></svg>
            <svg *ngIf="asset.file_type === 'XLS' || asset.file_type === 'XLSX'" cIcon name="cilFileXls"></svg>
            <svg *ngIf="asset.file_type === 'CSV'" cIcon name="cilSpreadsheet"></svg>
          </figure>

          <!-- Tag & Error message -->
          <div class="flex-1 ps-3 overflow-auto">
            <!-- File name -->
            <div class="mb-2">{{asset.file_name}}</div>

            <!-- Input tags -->
            <app-text-input *ngIf="currentStep === 'PREVIEW'" [config]="inputTagsConfig[i]"
              (onChange)="setFormValue($event, i)">
            </app-text-input>

            <!-- Display tags -->
            <div *ngIf="currentStep !== 'PREVIEW' && asset.tags" class="tags-container">
              <div *ngFor="let tag of asset.tags.split(',')" class="label label-tag">{{tag}}</div>
            </div>

            <!-- Display error -->
            <div *ngIf="isError(i) || asset.unsupportedFileType || asset.exceedSizeLimit"
              class="text-danger mt-2 fs-small">{{errorMessage[i]}}</div>
          </div>

          <!-- Icon -->
          <div>
            <span *ngIf="currentStep !== 'PREVIEW' && isProcessing(i)" class="icon-img icon-spin icon-spinner">
              <svg cIcon name="cilSync" size="xl"></svg>
            </span>
            <span *ngIf="currentStep !== 'PREVIEW' && isDone(i)" class="icon-img text-success">
              <svg cIcon name="cilCheckAlt" size="xl"></svg>
            </span>
            <span *ngIf="isError(i)" class="icon-img text-danger">
              <svg cIcon name="cilWarning" size="xl"></svg>
            </span>
          </div>
        </div>
      </c-card-body>
    </c-card>
  </ng-container>
</c-modal-body>
<c-modal-footer *ngIf="currentStep !== 'PROCESSING'">
  <ng-container *ngIf="currentStep === 'PREVIEW'">
    <button cButton color="secondary" (click)="dismissModal()">Cancel</button>
    <button cButton color="primary" (click)="goToProcessAssets()">Continue</button>
  </ng-container>

  <ng-container *ngIf="currentStep === 'DONE'">
    <div *ngIf="progressArr.done.length > 0" class="flex-1 text-start fs-small pe-3">
      Upload progress has been completed. You may close this window.</div>
    <button cButton color="primary" (click)="dismissModal()">Close</button>
  </ng-container>
</c-modal-footer>
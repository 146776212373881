import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { Observable, map } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    name: string = '';
    email: string = '';
    adminRole: string = '';
    allowed_cms_languages: number[] = [];

    constructor(
        private http: HttpClient,
    ) { }

    // Get admin details
    getAdminProfile(): Observable<any> {
        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.accountPath}/profile`).pipe(
            map(res => {
                if (res.data) {
                    this.name = res.data.name;
                    this.email = res.data.email;
                    this.adminRole = res.data.role;
                    if (!res.data.allowed_cms_languages || res.data.allowed_cms_languages.length <= 0) {
                        this.allowed_cms_languages = [1, 4852764, 4938438, 5017431, 4561158, 5028697, 5146990]
                    } else {
                        this.allowed_cms_languages = res.data.allowed_cms_languages;
                    }
                }
                return res;
            })
        );
    }
}